// extracted by mini-css-extract-plugin
export var textContainer = "text-module--text-container--1mzmQ";
export var textMain = "text-module--text-main--3S-pM";
export var textSub = "text-module--text-sub--35hxR";
export var textAnimated = "text-module--text-animated--pbrkH";
export var textCard = "text-module--text-card--3W9ZF";
export var textImage = "text-module--text-image--1xUKI";
export var textImage3 = "text-module--text-image3--c1Sp8";
export var mover2 = "text-module--mover2--2u0hL";
export var textImage4 = "text-module--text-image4--10IHX";
export var mover = "text-module--mover--1FQ9h";
export var textImage5 = "text-module--text-image5--nxFLr";
export var rotation = "text-module--rotation--smhxT";