// extracted by mini-css-extract-plugin
export var card = "quotecard-module--card--1GJfI";
export var upcomingCard = "quotecard-module--upcomingCard--2FA-i";
export var cardLayer = "quotecard-module--cardLayer--32TSy";
export var upcomingCardLayer = "quotecard-module--upcomingCardLayer--1hWzz";
export var imgContainer = "quotecard-module--imgContainer--2Qoa-";
export var upcomingEvents = "quotecard-module--upcomingEvents--2H9C_";
export var content = "quotecard-module--content--2Rutu";
export var upcomingEventsContent = "quotecard-module--upcomingEventsContent--ZFaWA";
export var description = "quotecard-module--description--s3dNa";
export var upcomingEventsDescription = "quotecard-module--upcomingEventsDescription--MQt38";
export var header = "quotecard-module--header--3kuyz";